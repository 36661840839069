/**
 * Site header
 */
.site-header {
    border-top: 1px solid $grey-color-light;
    border-bottom: $site-line;
    min-height: 56px;

    // Positioning context for the mobile navigation icon
    position: relative;
}

.site-title {
    font-size: 26px;
    font-weight: 300;
    line-height: 56px;
    letter-spacing: -1px;
    margin: 0;
    float: left;

    &,
    &:visited {
        color: $grey-color-dark;
    }
}

.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

  a {
    color: $brand-color;
    text-decoration: none;

    &:visited {
        color: darken($text-color, 15%);
    }
    &:hover {
        color: lighten($text-color, 50%);
        text-decoration: none;
    }
  }
    .page-link {
        color: $text-color;
        line-height: 56px;
        font-size: 24px;

        // Gaps between nav items, but not on the last one
        &:not(:last-child) {
            margin-right: 20px;
        }

    }

    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: calc(#{$spacing-unit} / 2);
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;


            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;
            
            &:not(:last-child) {
                margin-right: 0;
            }
            margin-left: 20px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    border-top: $site-line;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: calc(#{$spacing-unit} / 2);
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -calc(#{$spacing-unit} / 2);
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: calc(#{$spacing-unit} / 2);
    padding-left: calc(#{$spacing-unit} / 2);
}

.footer-col-1 {
    width: -webkit-calc(33% - (#{$spacing-unit} / 2));
    width:         calc(33% - (#{$spacing-unit} / 2));
    text-align: left;
}

.footer-col-2 {
    width: -webkit-calc(33% - (#{$spacing-unit} / 2));
    width:         calc(33% - (#{$spacing-unit} / 2));
    text-align: center;
}

.footer-col-3 {
    width: -webkit-calc(33% - (#{$spacing-unit} / 2));
    width:         calc(33% - (#{$spacing-unit} / 2));
    text-align: right;
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
        text-align: left;
    }
    .footer-col-2 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
        text-align: left;
    }
    .footer-col-1 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
        text-align: left;
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    margin-bottom: 0;
    list-style: none;

    > li {
        margin-bottom: 0;
    }
    > h2 {
        margin-bottom: 0;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 16px;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}

.suse .post-header {
  h1 {
    color: #33ff33;
  }
}
.redhat .post-header {
  h1 {
    color: #ff3333;
  }
}

.alignright {
  float: right;
  margin: 0 0 1em 1em;
}

.banner {
    width: 100%;
    margin: auto;
    padding-bottom: 30px;
}

.content-col-wrapper {
    font-size: 15px;
    margin-left: -calc(#{$spacing-unit} / 2);
    @extend %clearfix;
}

.content-col {
    float: left;
    margin-bottom: calc(#{$spacing-unit} / 2);
    padding-left: calc(#{$spacing-unit} / 2);
}

.content-col-int {
    width: -webkit-calc(33% - (#{$spacing-unit} / 2));
    width:         calc(33% - (#{$spacing-unit} / 2));
    text-align: left;
}

.content-col-bigint {
    width: -webkit-calc(66% - (#{$spacing-unit} / 2));
    width:         calc(66% - (#{$spacing-unit} / 2));
    text-align: left;
}


@include media-query($on-palm) {
    .content-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
    .content-col-int {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
        text-align: left;
    }
    .content-col-bigint {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
        text-align: left;
    }
}

